import React from 'react';
import Layout from "../components/layout"

const Susbcribe = () => (
  <Layout isExternal withoutFooter>
    <div className="relative h-auto xl:h-screen pt-24 pb-8">
    <iframe frameBorder="0" id="iframewin" width="100%" height="100%" src="https://inzo.maillist-manage.com/ua/Optin?od=11287ecaff6499&zx=129554de3&lD=17f52c15de9947e9&n=11699f75041d3b5&sD=17f52c15de9947f9" />
    </div>
  </Layout>
);

export default Susbcribe;
